var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.SealAssignmentList'))+" ")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.SealAssignment'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.SealAssignment'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item, index }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.UnNumber'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.UnNumber'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","square":"","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('modalSealAssignment',{attrs:{"modal":_vm.modalUnNumberCreate,"isEdit":_vm.isEdit,"sealAssignment":_vm.sealAssignment},on:{"update:modal":function($event){_vm.modalUnNumberCreate=$event},"Update":_vm.Update,"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }